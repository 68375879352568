/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    /**** Delay firing of event ****/
    var waitForFinalEvent = (function () {
      var timers = {};
      return function (callback, ms, uniqueId) {
        if (!uniqueId) {
          uniqueId = "Don't call this twice without a uniqueId";
        }
        if (timers[uniqueId]) {
          clearTimeout (timers[uniqueId]);
        }
        timers[uniqueId] = setTimeout(callback, ms);
      };
    })();
  
    formFunctions = function() {
  
      var formFunctions = {};
  
      formFunctions.checkable = function() {
  
          var projectType = $("[name='checkbox-projecttype[]']"),
              projectReqs = $("[name='checkbox-requirements[]']"),
              projStart = $("[name='radio-start[]']"),
              projEnd = $("[name='radio-complete[]']");
  
          projectType.on('click', function(){
            $(this).parent().parent().toggleClass('active');
            $(this).parent().parent().siblings().removeClass('active');
          });
  
          $(projectReqs).on('click', function(e) {
  
            if ($(this).val() === 'Not Sure') {
              $(this).parent().parent().siblings().removeClass('active');
              $(this).parent().parent().addClass('active');
            } else {
              $(this).parent().parent().toggleClass('active');
              $(this).parent().parent().siblings('.last').removeClass('active');
            }
  
          });
  
          $(projStart).add(projEnd).on('click', function(){
            $(this).parent().toggleClass('active');
          });
  
      }();
      
      return formFunctions;
  
    };
  
    /****  adds portrait and landscape classes to images ****/
    function addImageClass() {
  
      var imageContainer = $('img.post-thumbnail');
  
      imageContainer.each(function(i, val){
       $(this).addClass( (this.width > this.height ) ? 'landscape' : 'portrait');
      })
  
    }
  
  $.extend($.easing, {
      easeInOutQuart: function (x, t, b, c, d) {
          if ((t/=d/2) < 1) return c/2*t*t*t*t + b;
          return -c/2 * ((t-=2)*t*t*t - 2) + b;
      },
      easeInOutExpo: function (x, t, b, c, d) {
          if (t==0) return b;
          if (t==d) return b+c;
          if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
          return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
      }
  });
  
    // var body = document.body,
    //     timer;
  
    // window.addEventListener('scroll', function() {
    //   clearTimeout(timer);
    //   if(!body.classList.contains('disable-hover')) {
    //     body.classList.add('disable-hover')
    //   }
      
    //   timer = setTimeout(function(){
    //     body.classList.remove('disable-hover')
    //   }, 250);
    // }, false);
    // 
  
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
      // offset_value : parseInt($('#more_posts').data('postcount')),
      // workPostCount : parseInt($('#more_posts').data('postcount')),
      bigBeeScrolling: function() {
        $scrollTop = $('body').scrollTop();
        $('.list--name').css('transform','translate3d(0,' + ( $scrollTop / 3) + 'px, 0)');
        // requestAnimationFrame(Sage.bigBeeScrolling);
      },
      swap : $('.swap-grid, .swap-grid-news'),
      // All pages
      'common': {
        init: function() {
          // JavaScript to be fired on all pages
          // SET GLOBAL VARIABLES HERE
            var firstRun = true,
                mobFlag,
                wideFlag,
                win = $(window),
                $main = $('.main'),
                $bd = $('body'),
                $list = $('.list--name'),
                screenH = win.height(),
                screenW = win.width(),
                $smbp = 640,
                $mdbp = 1024,
                $lgbp = 1440,
                is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
                $mainColor = 'red',
                $xlbp = 1920;
  
                $('.switch input').on('click', function(e){
                  $('.single article').toggleClass('lightenup');
                });
  
                if (('ontouchstart' in window) ||
                     (navigator.maxTouchPoints > 0) ||
                     (navigator.msMaxTouchPoints > 0)) {
                      /* browser with either Touch Events of Pointer Events
                         running on touch-capable device */
                         $bd.addClass('touch-capable');
                }
        
                if (is_firefox) {
                  $bd.addClass('firefox');
                }
  
                function reColorForever() {
                    
                    $('.brand span, li.active').each(function(index, val) {
                        var that = $(this);
                        var clr = randomColor({
                          hue: '#003fff',
                          format: 'hex'
                        });
                        
                        that.css({
                          'color':clr
                        });
  
                    });    
  
                }
  
  
                function onLoadDoThis() {
  
                      if ( $('.b-lazy').length ) {
                          bLazy = new Blazy({
                            breakpoints: [{
                                width: 420, // Max-width
                                src: 'data-src-small'
                            }],
                            success: function(ele){
                                var $parent = $(ele).parents('.next-in-line');
                                    
                                        $parent.addClass('image-is-visible');
                                        $(ele).removeAttr('style');
                                    // waitForFinalEvent(function(){
                                      // console.log('blazy success');
                                      bLazy.revalidate();
                                      Sage.swap.masonry();
                                      addImageClass();
                                    // }, 150);
                            }
                          });
                      }
  
                      if ( $('body').is('.single, .blog')  ) {
                        Prism.highlightAll();
                        $('[class*="addtoany"]').each(function(){
                          a2a.init('page');
                        });
                      }
  
                      $('.main').addClass('main-visible');
                      
                      $(document).off('touchmove scroll');
  
                      $('.switch input').on('click', function(e){
                        $('.single article').toggleClass('lightenup');
                      })
  
                      // setTimeout(function() {
                          $(document).on('touchmove scroll layoutComplete', function() {
                            requestAnimationFrame(Sage.bigBeeScrolling);
                              waitForFinalEvent(function(){
                                  if ( $('.b-lazy').length ) {
                                    bLazy.revalidate();
                                  }
                                  if ( $('.swap-grid, .swap-grid-news').length ) {
                                    $('.swap-grid').masonry();
                                  }
                              }, 100, 'uwotm8');
                          });
                      // }, 100);
  
                      if ( jQuery('.swap-grid').length ) {
  
                          Sage.offset_value = parseInt($('#more_posts').data('postcount'));
                          Sage.workPostCount = parseInt($('#more_posts').data('postcount'));
  
                          var msnry = jQuery('.swap-grid').masonry({
                            columnWidth: '.next-in-line',
                            itemSelector: '.next-in-line',
                            gutter: 0,
                            stagger: 300,
                            transitionDuration: '0',
                            hiddenStyle: {
                              opacity: 0,
                              transform:'translateY(120px) scale(0)'
                            },
                            visibleStyle: {
                              opacity: 1,
                              transform:'translateY(0px) scale(1)'
                            }
                          });
  
                          parallaxFunc();
  
                          $(document).off('click');
  
                          setTimeout(function() {
                              $(document).on('click', '.ajax--callme', function(e){
                                e.preventDefault();
                                e.stopPropagation();
                                ajaxify();
                              });                          
                            }, 100);
  
                      }
                      
                      if ( $('.swap-grid-news').length ) {
                         var msnrytoo = jQuery('.swap-grid-news').masonry({
                            columnWidth: '.grid-sizer',
                            itemSelector: '.next-in-line',
                            gutter: 0,
                            stagger: 300,
                            transitionDuration: '0',
                            hiddenStyle: {
                              opacity: 0
                            },
                            visibleStyle: {
                              opacity: 1
                            }
                          });
                      }
  
                    if ( $('body').is('.contact') ) {

                        $('.btn--planner').on('click', function(e){
                          var formblock = $('.hide-block'); 
                          e.preventDefault()
                          $(this).hide();
                          formblock.show();
                              $('html, body').animate({
                                scrollTop:formblock.offset().top
                              }, 1750, 'easeInOutExpo');
                        })
                        
                        formFunctions();
                          
                    }
                      
                }
  
                win.on('load', onLoadDoThis);
  
                smoothState = $('#of-contents').smoothState({
                  prefetch: true,
                  cacheLength: 2,
                  blacklist : ".no-smoothState, a[data-popup], .wpcf7-form",
                  development : true,
                  onStart: {
                    duration: 750, // Duration of our animation
                    render: function (_container) {
                      $('#menu-header-navigation .active').removeClass('active');
  
                      Sage.offset_value = Sage.workPostCount;
  
                      _container.addClass('is-exiting');
  
                      $('.main').removeClass('main-visible');
  
                      reColorForever();
                      
                      $('html, body').animate({
                        scrollTop:0
                      }, 500);
  
                    }
                  },
                  onReady : {
                    duration: 0, // Duration of the animations, if any.
                    render: function (_container, _content) {
                      // Remove your CSS animation reversing class
                      _container.removeClass('is-exiting');
  
                      // _container.html(_content);
  
                      _container.find('.wrap').html(_content.filter('.wrap').unwrap());
  
                      var url = smoothState.href // <-- get the current url
                      var doc = smoothState.cache[url].doc // <-- full html response
                      $html = $.htmlDoc(doc);
                      var meta = {};
                      meta.bodyId = $html.find('body').attr('id');
                      meta.bodyClasses = $html.find('body').attr('class');
                      meta.bodyCSS = $html.find('body').attr('style');
  
                      $('body').removeClass().addClass(meta.bodyClasses);
                      $('body').attr('id', meta.bodyId);
                      $('body').attr('style', meta.bodyCSS);
  
                    }
                  },
                  onAfter : function(_container, _content) {
                    
                    onLoadDoThis();
                    
                    $('.brand span').each(function(i) {
                      $(this).delay((i++) * 50).queue(function(){
                          $(this).removeAttr('style').stop().dequeue();
                      });
                    });

                    console.log('its over yeh');
                  }
                }).data('smoothState');
                
                /***** parallax effect on work posts *****/
                function parallaxFunc() {
  
                    $('.inner-container').on('mousemove', function(e) {
  
                            var w = $(window).width();
                            var h = $(window).height();
                            var offsetX = 0.35 - e.pageX / w;
                            var offsetY = 0.35 - e.pageY / h;
  
                            $('.parallax', this).each(function(i, el) {
  
                                var offset = parseInt($(el).data('offset'));
                                var translate = "translate3d(" + Math.round(offsetX * offset) + "px," + Math.round(offsetY * offset) + "px, 0px)";
  
                                $(el).css({
                                    '-webkit-transform': translate,
                                    'transform': translate,
                                    'moz-transform': translate
                                });
                            });
  
                    });
  
                    $('.inner-container').on('mouseleave', function(){
                      $(this).find('.parallax').css('transform', 'translate3d(0,0,0)');
                    });
  
                }
  
              // AJAXIFY FUNCTION FOR FRONT PAGE POSTS
  
              function ajaxify() {
  
                  var container = $('.swap-grid'),
                      ajaxBtn = $('.ajax--callme'),
                      total_posts = parseInt(ajaxBtn.data('totalposts')),
                      post_type_select = ajaxBtn.data('category');
  
                  $.ajax({
                    url: ajaxinfo.url,
                    type: 'POST',
                    dataType: 'html',
                    data: {
                      action: 'work_posts_ajaxify',
                      derp:'',
                      offset: Sage.offset_value,
                      post_type: post_type_select
                    },
                    beforeSend: function() {
                      $(document).off('click');
                    },
                    success: function(html) {
  
                      var jQhtml = $(html);
  
                      if (jQhtml.length) {
  
                        container.append(jQhtml);
  
                        container.imagesLoaded(function(){
  
                          Sage.offset_value += jQhtml.length;
  
                          container.masonry( 'appended', jQhtml );
                          addImageClass();
                          parallaxFunc();
  
                          $(document).on('click', ajaxBtn, function(e){
                            e.preventDefault();
                            e.stopPropagation();
                            ajaxify();
                          });
  
                          if ( Sage.offset_value >= total_posts ) {
                            
                            Sage.offset_value = Sage.workPostCount;
  
                            ajaxBtn.addClass('hideme');
  
                            $(document).off('click');
  
                          }
  
                        }) // end imagesLoaded
  
                      }
  
                    },
                    done: function() {
                        $(document).off('click');
                    }
                  })
  
                  return false;
  
              }
            
              function windowSizer() {
  
                var winSize = win.width() > $mdbp === true ? wideFlag = true : wideFlag = false;
  
                // if (firstRun === true){
                //   console.log('winSize' + ' = ' + winSize + ' THIS IS THE FIRST RUUUUN');
                // } else {
                //   console.log('winSize' + ' = ' + winSize + ' THIS IS NOT THE FIRST RUN');
                // }
  
                firstRun = false;
              }
  
              windowSizer();
  
              win.on('resize', function(){
                waitForFinalEvent(function(){
                  windowSizer();
                }, 500, "uniqueID");
              });
  
        },
        finalize: function() {
            
        }
      },
      // Home page
      'home': {
        init: function() {
          // JavaScript to be fired on the home page
        },
        finalize: function() {
          // JavaScript to be fired on the home page, after the init JS
        }
      },
      'contact': {
        init: function() {
          // JavaScript to be fired on the home page
  
        },
        finalize: function() {
          // JavaScript to be fired on the home page, after the init JS
        }
      },
      // About us page, note the change from about-us to about_us.
      'about_us': {
        init: function() {
          // JavaScript to be fired on the about us page
        }
      }
    };
  
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
      fire: function(func, funcname, args) {
        var fire;
        var namespace = Sage;
        funcname = (funcname === undefined) ? 'init' : funcname;
        fire = func !== '';
        fire = fire && namespace[func];
        fire = fire && typeof namespace[func][funcname] === 'function';
  
        if (fire) {
          namespace[func][funcname](args);
        }
      },
      loadEvents: function() {
        // Fire common init JS
        UTIL.fire('common');
  
        // Fire page-specific init JS, and then finalize JS
        $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, 'finalize');
        });
  
        // Fire common finalize JS
        UTIL.fire('common', 'finalize');
      }
    };
  
    // Load Events
    $(document).ready(UTIL.loadEvents);
  
  })(jQuery); // Fully reference jQuery after this point.
  